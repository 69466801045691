import React from 'react';
import { SearchHeroHome } from './SearchHeroListing/SearchHeroListing';
export const customizedBlocks = [
  {
    id: '9eb1cdca-3fc3-4345-a8e8-caafa1ed830d', // generated by https://www.uuidgenerator.net/version4
    component: <SearchHeroHome></SearchHeroHome>,
    valid: true,
  },
  // create your new own customized blocks here
];
export const notFoundCustomizedBlock = {
  id: undefined, // generated by https://www.uuidgenerator.net/version4
  component: <div>Customized block has not been created or is invalid!</div>,
  valid: true,
};
export const notConfiguredCustomizedBlock = {
  id: undefined, // generated by https://www.uuidgenerator.net/version4
  component: <div>Customized block have not required parameters!</div>,
  valid: true,
};
